<template>
    <div class="p-4">
        <b-tabs content-class="mt-3" justified>
            <b-tab title="Users" active>
                <div class="row">
                    <div class="col-lg-12">
                        <b-button @click="startCreatingUser">ADD NEW USER</b-button>
                        <div class="table-responsive bg-white shadow rounded">
                            <table class="table mb-0 table-center" style="table-layout:fixed;">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width:5%; text-align:center; cursor: pointer;">
                                            ID
                                        </th>
                                        <th scope="col" style="width:41%; text-align:center;">
                                            Логин
                                        </th>
                                        <th scope="col" style="width:10%; text-align:center;">
                                            Политики
                                        </th>
                                        <th scope="col" style="width:10%; text-align:center; cursor: pointer;">
                                            Редактировать
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(x, id) in users">
                                        <tr>
                                            <td style="text-align:center;">
                                                {{ x.id }}
                                            </td>
                                            <td>
                                                {{ x.username }}
                                            </td>
                                            <td>
                                                <ul>
                                                    <li v-for="policy in x.policies ">
                                                        {{ policy.name }} {{ (policy.description) ? " - " +
                            policy.description :
                            "" }}
                                                    </li>
                                                </ul>
                                            </td>
                                            <td style="text-align:center;">
                                                <a @click="getUserView(x.id)">
                                                    <arrow-right-circle-icon class="fea icon-sm"
                                                        style="cursor: pointer;"></arrow-right-circle-icon>
                                                </a>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <b-modal id="user-details-modal" size="xl" hide-footer>
                    <b-button v-b-toggle.collapse-userPolicies variant="primary">Редактировать политики</b-button>
                    <b-collapse id="collapse-userPolicies" class="mt-2">
                        <div class="table-responsive bg-white shadow rounded">
                            <table class="table mb-0 table-center" style="table-layout:fixed;">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width:15%; text-align:left; cursor: pointer;">
                                            Активно
                                        </th>
                                        <th scope="col" style="width:30%; text-align:left;">
                                            Политика
                                        </th>
                                        <th scope="col" style="width:40%; text-align:left;">
                                            Описание
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(x, id) in selectedUserPolicies">
                                        <tr>
                                            <td style="text-align:center;">
                                                <input type="checkbox" v-model="x.enabled">
                                            </td>
                                            <td>
                                                {{ x.name }}
                                            </td>
                                            <td>
                                                {{ x.description }}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </b-collapse>
                    <b-button v-b-toggle.collapse-userHandles variant="primary">Смотреть доступы</b-button>
                    <b-collapse id="collapse-userHandles" class="mt-2">
                        <div class="table-responsive bg-white shadow rounded">
                            <table class="table mb-0 table-center" style="table-layout:fixed;">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width:30%; text-align:left; cursor: pointer;">
                                            Ресурс
                                        </th>
                                        <th scope="col" style="width:40%; text-align:left;">
                                            Описание
                                        </th>
                                        <th scope="col" style="width:20%; text-align:left;">
                                            Политики, дающие доступ
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(x, id) in selectedUserResources">
                                        <tr>
                                            <td>
                                                {{ x.name }}
                                            </td>
                                            <td>
                                                {{ x.description }}
                                            </td>
                                            <td>
                                                <ul>
                                                    <li v-for="pol in x.policies">{{ pol }}</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </b-collapse>
                    <b-button v-b-toggle.collapse-changeUserPassword variant="primary">Сменить пароль</b-button>
                    <b-collapse id="collapse-changeUserPassword" class="mt-2">

                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Новый пароль <span class="text-danger"></span></label>
                                <div class="position-relative">
                                    <user-icon class="fea icon-sm icons"></user-icon>
                                    <input type="email" class="form-control pl-5" required=""
                                        v-model="potentialPassword" />
                                </div>
                            </div>
                        </div>
                        <b-button class="mt-3" @click="createRandomPass"> Сгенерировать случайный пароль</b-button>

                    </b-collapse>
                    <b-button class="mt-3" @click="checkConfirm"
                        :variant="!confOne ? 'warning' : (confOne && !confTwo) ? 'danger' : 'success'"
                        v-text="!confOne ? 'Удалить пользователя' : (confOne && !confTwo) ? 'Точно удалить?' : 'Потрачено'"></b-button>
                    <b-button class="mt-3" block @click="persistUserPolicies">Сохранить изменения</b-button>
                </b-modal>
                <b-modal id="add-user-modal" size="xl" hide-footer>
                    <form class="login-form mt-4" v-on:submit.prevent="addNewUser">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Email <span class="text-danger">*</span></label>
                                    <div class="position-relative">
                                        <user-icon class="fea icon-sm icons"></user-icon>
                                        <input type="email" class="form-control pl-5" placeholder="Email" name="email"
                                            required="" v-model="newUserData.username" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Пароль <span class="text-danger">*</span></label>
                                    <div class="position-relative">
                                        <key-icon class="fea icon-sm icons"></key-icon>
                                        <input type="password" class="form-control pl-5" placeholder="Пароль"
                                            required="" v-model="newUserData.password" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Требовать верификации (пока недоступно)<span
                                            class="text-danger">*</span></label>
                                    <div class="position-relative">
                                        <input type="checkbox" class="form-control pl-1" required="" disabled
                                            v-model="newUserData.require_verification" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Политики<span class="text-danger">*</span></label>
                                    <div class="table-responsive bg-white shadow rounded">
                                        <table class="table mb-0 table-center" style="table-layout:fixed;">
                                            <thead>
                                                <tr>
                                                    <th scope="col"
                                                        style="width:15%; text-align:left; cursor: pointer;">
                                                        Активно
                                                    </th>
                                                    <th scope="col" style="width:30%; text-align:left;">
                                                        Политика
                                                    </th>
                                                    <th scope="col" style="width:40%; text-align:left;">
                                                        Описание
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(x, id) in policyListForNewUser">
                                                    <tr>
                                                        <td style="text-align:center;">
                                                            <input type="checkbox" v-model="x.enabled">
                                                        </td>
                                                        <td>
                                                            {{ x.name }}
                                                        </td>
                                                        <td>
                                                            {{ x.description }}
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div id="div-with-loading" class="col-lg-12 mb-0 mt-3 vs-con-loading__container">
                                <button ref="loginButton" size="xl" type="submit" class="btn btn-primary btn-block ">
                                    Добавить пользователя
                                </button>
                            </div>
                        </div>
                    </form>
                </b-modal>
            </b-tab>
            <b-tab title="Policies">
                <div class="row">
                    <div class="col-lg-12">
                        <b-button @click="startCreatingPolicy">ADD NEW POLICY</b-button>
                        <div class="table-responsive bg-white shadow rounded">
                            <table class="table mb-0 table-center" style="table-layout:fixed;">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width:5%; text-align:center; cursor: pointer;">
                                            ID
                                        </th>
                                        <th scope="col" style="width:20%; text-align:center;">
                                            Название
                                        </th>
                                        <th scope="col" style="width:30%; text-align:center;">
                                            Описание
                                        </th>
                                        <th scope="col" style="width:30%; text-align:center;">
                                            Ресурсы
                                        </th>
                                        <th scope="col" style="width:10%; text-align:center; cursor: pointer;">
                                            Редактировать
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(x, id) in policies">
                                        <tr>
                                            <td style="text-align:center;">
                                                {{ x.id }}
                                            </td>
                                            <td>
                                                {{ x.name }}
                                            </td>
                                            <td>
                                                {{ x.description }}
                                            </td>
                                            <td>
                                                <ul>
                                                    <li v-for="res in x.resources ">
                                                        {{ res }}
                                                    </li>
                                                </ul>
                                            </td>
                                            <td style="text-align:center;">
                                                <a @click="getPolicyView(x.id)">
                                                    <arrow-right-circle-icon class="fea icon-sm"
                                                        style="cursor: pointer;"></arrow-right-circle-icon>
                                                </a>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <b-modal id="policy-details-modal" size="xl" hide-footer>
                    <b-button v-b-toggle.collapse-policyResources variant="primary">Редактировать ресурсы</b-button>
                    <b-collapse id="collapse-policyResources" class="mt-2">
                        <div class="table-responsive bg-white shadow rounded">
                            <table class="table mb-0 table-center" style="table-layout:fixed;">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width:15%; text-align:left; cursor: pointer;">
                                            Активно
                                        </th>
                                        <th scope="col" style="width:30%; text-align:left;">
                                            Ресурс
                                        </th>
                                        <th scope="col" style="width:40%; text-align:left;">
                                            Описание
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(x, id) in selectedPolicyResources">
                                        <tr>
                                            <td style="text-align:center;">
                                                <input type="checkbox" v-model="x.enabled">
                                            </td>
                                            <td>
                                                {{ x.name }}
                                            </td>
                                            <td>
                                                {{ x.description }}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </b-collapse>
                    <b-button v-b-toggle.collapse-policyHandles variant="primary">Смотреть ручки</b-button>
                    <b-collapse id="collapse-policyHandles" class="mt-2">
                        <div class="table-responsive bg-white shadow rounded">
                            <table class="table mb-0 table-center" style="table-layout:fixed;">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width:30%; text-align:left; cursor: pointer;">
                                            Ресурс
                                        </th>
                                        <th scope="col" style="width:40%; text-align:left;">
                                            Где
                                        </th>
                                        <th scope="col" style="width:20%; text-align:left;">
                                            Включена в ресурс
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(x, id) in selectedPolicyHandles">
                                        <tr>
                                            <td>
                                                {{ x.value }}
                                            </td>
                                            <td>
                                                {{ x.location }}
                                            </td>
                                            <td>
                                                <ul>
                                                    <li v-for="res in x.resources">{{ res }}</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </b-collapse>
                    <b-button class="mt-3" @click="checkConfirmPol"
                        :variant="!confOnePol ? 'warning' : (confOnePol && !confTwoPol) ? 'danger' : 'success'"
                        v-text="!confOnePol ? 'Удалить политику' : (confOnePol && !confTwoPol) ? 'Точно удалить?' : 'Потрачено'"></b-button>
                    <b-button class="mt-3" block @click="persistPolicyResources">Сохранить изменения</b-button>
                </b-modal>
                <b-modal id="add-policy-modal" size="xl" hide-footer>
                    <form class="login-form mt-4" v-on:submit.prevent="addNewPolicy">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Название <span class="text-danger">*</span></label>
                                    <div class="position-relative">
                                        <user-icon class="fea icon-sm icons"></user-icon>
                                        <input class="form-control pl-5" placeholder="Название" name="name" required=""
                                            v-model="newPolicyData.name" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Описание <span class="text-danger">*</span></label>
                                    <div class="position-relative">
                                        <key-icon class="fea icon-sm icons"></key-icon>
                                        <input class="form-control pl-5" placeholder="Описание" required=""
                                            v-model="newPolicyData.description" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Ресурсы<span class="text-danger">*</span></label>
                                    <div class="table-responsive bg-white shadow rounded">
                                        <table class="table mb-0 table-center" style="table-layout:fixed;">
                                            <thead>
                                                <tr>
                                                    <th scope="col"
                                                        style="width:15%; text-align:left; cursor: pointer;">
                                                        Активно
                                                    </th>
                                                    <th scope="col"
                                                        style="width:20%; text-align:left; cursor: pointer;">
                                                        Название
                                                    </th>
                                                    <th scope="col" style="width:25%; text-align:left;">
                                                        Описание
                                                    </th>
                                                    <th scope="col" style="width:20%; text-align:left;">
                                                        Группа
                                                    </th>
                                                    <th scope="col" style="width:20%; text-align:left;">
                                                        Точка входа
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(x, id) in resourceListForNewPolicy">
                                                    <tr>
                                                        <td style="text-align:center;">
                                                            <input type="checkbox" v-model="x.enabled">
                                                        </td>
                                                        <td>
                                                            {{ x.name }}
                                                        </td>
                                                        <td>
                                                            {{ x.description }}
                                                        </td>
                                                        <td>
                                                            {{ x.group }}
                                                        </td>
                                                        <td>
                                                            {{ x.entrypoint }}
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div id="div-with-loading" class="col-lg-12 mb-0 mt-3 vs-con-loading__container">
                                <button ref="createPolButton" size="xl" type="submit"
                                    class="btn btn-primary btn-block ">
                                    Добавить политику
                                </button>
                            </div>
                        </div>
                    </form>
                </b-modal>
            </b-tab>
        </b-tabs>
    </div>
</template>




<script>
import {
    ArrowRightCircleIcon,
    HomeIcon,
    UserIcon,
    KeyIcon,
} from "vue-feather-icons";

import {
} from "vue-feather-icons";


export default {
    name: 'AdminPage',
    components: {
        ArrowRightCircleIcon,
        HomeIcon,
        UserIcon,
        KeyIcon,
    },
    data() {
        return {
            confOne: false,
            confTwo: false,
            selectedUserId: -1,
            selectedUserPolicies: [],
            selectedUserResources: [],
            users: [],
            policies: [],
            handles: [],
            userFields: [
                { key: 'id', label: 'ID' },
                { key: 'username', label: 'Login' },
                { key: 'policies', label: 'Active polices' },
                { key: 'details', label: 'Edit' }
            ],
            userDetailsFields: [
                { key: 'enabled', label: 'Enabled' },
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Policy' },
                { key: 'description', label: 'Description' },
            ],
            selectedUserPoliciesOnStart: {},
            newUserData: {
                username: '',
                password: '',
                policies: [],
                require_verification: false
            },
            policyListForNewUser: [],
            resourceListForNewPolicy: [],
            newPolicyData: {
                name: '',
                description: '',
                resources: []
            },
            selectedPolicyHandles: [],
            selectedPolicyResources: [],
            confOnePol: false,
            confTwoPol: false,
            selectedPolicyId: -1,
            potentialPassword: ''
        }
    },
    mounted() {
        this.getAllLists();
    },
    methods: {
        getPolicyView(policyId) {
            this.selectedPolicyId = policyId;
            this.axios({
                method: 'get',
                url: `${this.$hostname_auth}/admin/policy/${policyId}`,
            }).then(res => {
                this.selectedPolicyHandles = res.data.handles;
                this.selectedPolicyResources = res.data.resources;
                this.$bvModal.show("policy-details-modal");
            })
        },
        createRandomPass() {
            this.potentialPassword = window.crypto.randomUUID();
        },
        persistPolicyResources() {

            let selectedPolicyResourcesToSend = this.selectedPolicyResources.filter(item => {
                return item.enabled
            }).map((item) => item.id);
            this.axios({
                method: 'post',
                url: `${this.$hostname_auth}/admin/policy/${this.selectedPolicyId}`,
                data: { resources: selectedPolicyResourcesToSend }
            }).then(res => {
                this.$vs.notify({
                    time: 4000,
                    title: 'Политики обновлены',
                    color: 'success'
                });
                this.$bvModal.hide("policy-details-modal");
            }
            )
        },
        checkConfirmPol() {
            if (this.confOnePol === false) {
                this.confOnePol = true
            } else {
                this.confTwoPol = true;
                this.axios({
                    method: 'delete',
                    url: `${this.$hostname_auth}/admin/policy/${this.selectedPolicyId}`,
                }).then((res) => {
                    this.$vs.notify({
                        time: 4000,
                        title: 'Политика удалена',
                        color: 'success'
                    });
                    this.getAllLists();
                    this.$bvModal.hide("policy-details-modal");
                    this.confOnePol = false;
                    this.confTwoPol = false;
                })
            }
        },
        checkConfirm() {
            if (this.confOne === false) {
                this.confOne = true
            } else {
                this.confTwo = true;
                this.axios({
                    method: 'delete',
                    url: `${this.$hostname_auth}/admin/user/${this.selectedUserId}`,
                }).then((res) => {
                    this.$vs.notify({
                        time: 4000,
                        title: 'Пользователь удален',
                        color: 'success'
                    });
                    this.getAllLists();
                    this.$bvModal.hide("user-details-modal");
                    this.confOne = false;
                    this.confTwo = false;
                })
            }
        },
        startCreatingPolicy() {
            this.resourceListForNewPolicy = this.resources.map(item => {
                return {
                    description: item.description,
                    entrypoint: item.entrypoint,
                    group: item.group,
                    id: item.id,
                    name: item.name,
                    enabled: false
                }
            })
            this.newPolicyData = {
                name: '',
                description: '',
                resources: []
            };
            this.$bvModal.show('add-policy-modal');
        },
        startCreatingUser() {
            this.policyListForNewUser = this.policies.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    enabled: false
                }
            })
            this.newUserData = {
                username: '',
                password: '',
                policies: [],
                require_verification: false,
            }
            this.$bvModal.show('add-user-modal');

        },
        getUserView(userId) {
            this.selectedUserId = userId;
            this.axios({
                method: 'get',
                url: `${this.$hostname_auth}/admin/user/${userId}`,
            }).then(res => {
                this.selectedUserPolicies = res.data.policies;
                this.selectedUserResources = res.data.resources;
                this.$bvModal.show("user-details-modal");
            })

        },
        persistUserPolicies() {
            let selectedUserPoliciesToSend = this.selectedUserPolicies.filter(item => {
                return item.enabled
            }).map((item) => item.id);
            this.axios({
                method: 'post',
                url: `${this.$hostname_auth}/admin/user/${this.selectedUserId}`,
                data: { policies: selectedUserPoliciesToSend, new_password: this.potentialPassword }
            }).then(res => {
                this.$vs.notify({
                    time: 4000,
                    title: 'Политики обновлены',
                    color: 'success'
                });
                this.$bvModal.hide("user-details-modal");
            })
        },
        addNewUser() {
            this.newUserData.policies = this.policyListForNewUser.filter(item => {
                return item.enabled
            }).map((item) => item.id);
            this.axios({
                method: 'post',
                url: `${this.$hostname_auth}/admin/create-user`,
                data: this.newUserData
            }).then((res) => {
                this.$vs.notify({
                    time: 4000,
                    title: 'Пользователь добавлен',
                    color: 'success'
                });
                this.$bvModal.hide("add-user-modal");
            }).catch(err => {
                this.$vs.notify({
                    time: 4000,
                    title: 'Ошибка',
                    text: 'Что-то пошло не так...',
                    color: 'danger'
                });
                this.$bvModal.hide("add-user-modal");

            })
        },
        addNewPolicy() {
            this.newPolicyData.resources = this.resourceListForNewPolicy.filter(item => {
                return item.enabled
            }).map((item) => item.id);
            this.axios({
                method: 'post',
                url: `${this.$hostname_auth}/admin/create-policy`,
                data: this.newPolicyData
            }).then((res) => {
                this.$vs.notify({
                    time: 4000,
                    title: 'Политика добавлена',
                    color: 'success'
                });
                this.$bvModal.hide("add-policy-modal");
            }).catch(err => {
                this.$vs.notify({
                    time: 4000,
                    title: 'Ошибка',
                    text: 'Что-то пошло не так...',
                    color: 'danger'
                });
                this.$bvModal.hide("add-policy-modal");

            })
        },
        getAllLists() {
            this.axios({
                method: 'get',
                url: '' + this.$hostname_auth + '/admin/admin-lists',
            }).then(res => {
                this.users = res.data.users;
                this.policies = res.data.policies;
                this.handles = res.data.handles;
                this.resources = res.data.resources;

            })
        }
    },

}
</script>


<style scoped>
.opened {
    background-color: #253649;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

/* .vs-table--header {
  background: rgb(40 61 85) !important;
} */

.vs-th {
    background: rgb(31 45 61);
}

.vs-table--tbody-table tr,
th {
    background: rgb(31 45 61);

}

.vs-con-table table {
    background: rgb(31 45 61);
}
</style>